<template>
  <div>
    <b-modal
      id="add-shift"
      size="xl"
      cancel-title="Anuluj"
      centered
      ok-title="Dalej"
      :ok-disabled="!selected.length"
      title="Dodaj zmianę"
      @ok="nextStep"
    >
      <b-alert show
        ><strong>Uwaga!</strong><br />Dobrana zmiana wpisywana jest do
        grafiku.</b-alert
      >
      <h5 class="mt-5">Wolne zmiany dzień</h5>
      <b-row>
        <b-col
          v-for="item in returnAvailable.days"
          :key="item.day + item.shift"
        >
          <div
            role="button"
            class="singleday d-flex flex-column justify-content-center"
            :class="[
              selected.find(
                (el) => el.day === item.day && el.shift === item.shift
              )
                ? 'selected'
                : '',
              item.currentDrivers >= item.totalCars ? 'reserve' : ''
            ]"
            @click="addShift(item)"
          >
            <div>
              <div class="day">
                <div v-if="item.currentDrivers >= item.totalCars">
                  Lista rezerwowa
                </div>

                {{ item.day }}.{{ item.month }}.{{ item.year }}
              </div>
              <div class="day">{{ item.dayWeek }}</div>
            </div>
          </div>
        </b-col>
      </b-row>

      <h5 class="mt-5">Wolne zmiany noc</h5>
      <b-row>
        <b-col
          v-for="item in returnAvailable.nights"
          :key="item.day + item.shift"
        >
          <div
            role="button"
            class="singleday d-flex flex-column justify-content-center"
            :class="[
              selected.find(
                (el) => el.day === item.day && el.shift === item.shift
              )
                ? 'selected'
                : '',
            ]"
            @click="addShift(item)"
          >
            <div>
              <div class="day">
                {{ item.day }}.{{ item.month }}.{{ item.year }}
              </div>
              <div class="day">{{ item.dayWeek }}</div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal
      title="Twoje dane"
      :ok-disabled="formValidate"
      @ok="sendForm"
      ok-title="Prześlij"
      cancel-title="Anuluj"
      centered
      size="lg"
      ref="final-step"
    >
      <b-alert show>Pamiętaj aby wprowadzić poprawny numer telefonu!</b-alert>
      <b-form-group :invalid-feedback="invalidName">
        <label for="fullName">Imię i nazwisko</label>
        <b-form-input
          v-model="fullName"
          :state="!formValidate || null"
          trim
          id="fullName"
        />
      </b-form-group>
      <b-form-group>
        <label for="email">Adres e-mail</label>
        <b-form-input v-model="email" trim type="email" id="email" />
      </b-form-group>
      <b-form-group
        label="Numer telefonu"
        label-for="phoneNumber"
        invalid-feedback="Wprowadź poprawny numer telefonu"
      >
        <b-input-group prepend="+48">
          <b-form-input v-model="phone" trim type="number" id="phoneNumber" />
        </b-input-group>
      </b-form-group>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: ["freeDays", "week", "year", "returnCal", "mode"],
  data() {
    return {
      selected: [],
      fullName: null,
      phone: null,
      formSent: false,
      email: null,
    };
  },
  methods: {
    addShift(item) {
      const isItem = this.selected.findIndex(
        (el) => el.shift === item.shift && el.day === item.day
      );
      if (isItem >= 0) {
        this.selected.splice(isItem, 1);
      } else {
        let shiftOption = item.shift === "day" ? "Dzień" : "Noc";
        if(item.totalCars <= item.currentDrivers){
          shiftOption += " rezerwa"
        }
        const shift = item.shift;
        const day = item.day;
        const month = item.month;
        const year = item.year;
        const dayWeek = item.dayWeek;
        const isWeekend =
          item.dayWeek === "Sobota" || item.dayWeek === "Niedziela"
            ? true
            : false;
        this.selected.push({
          shiftOption,
          day,
          month,
          year,
          isWeekend,
          shift,
          dayWeek,
        });
      }
    },
    nextStep() {
      const phoneStorage = localStorage.getItem('phone')
      if(phoneStorage){
      this.phone = phoneStorage
      }
      this.$refs["final-step"].show();
    },
    sendForm() {
      this.formSent = true;
      const phone = this.phone;
      const fullName = this.fullName;
      const email = this.email;
      const week = this.week;
      const year = this.year;
      if (this.phone && this.fullName && this.email) {
        this.$store.dispatch("addAdditional", {
          fullName,
          phone,
          email,
          addedDays: this.selected,
          weekNum: week,
          year,
        });
        this.$emit("updateView")

      } else {
        this.$bvModal.msgBoxOk("Upewnij się, że podałeś wszystkie dane.", {
          headerBgVariant: "danger",
          title: "Wystąpił błąd",
          okVariant: "danger",
          headerTextVariant: "light",
          centered: true,
        });
      }
      // add emit to update view for driver
    },
  },
  computed: {
    returnAvailable() {
      const freeDays = this.freeDays.day.filter((el) => el.leftSpaces >= 0 && el.currentDrivers < el.totalCars + 1);
      const freeNights = this.freeDays.night.filter((el) => el.leftSpaces >= 0 && el.currentDrivers < el.totalCars + 1);
      return { days: freeDays, nights: freeNights };
    },
    formValidate() {
      if (
        this.fullName &&
        this.fullName.trim().indexOf(" ") !== -1 &&
        !this.formSent &&
        this.phone &&
        this.phone.length > 6 &&
        this.email &&
        this.email.length > 5
      ) {
        return false;
      } else {
        return true;
      }
    },
    invalidName() {
      if (this.fullName && this.fullName.trim().indexOf(" ") === -1) {
        return "Wprowadź imię i nazwisko";
      }
    },
  },
  watch: {
    mode: {
      handler(mode) {
        this.selected = [];
      },
    },
  },
};
</script>

<style>
.row .col .singleday {
  border: 1px solid black;
  text-align: center;
  height: 100px;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
.selected {
  background-color: #808080;
  color: #fff;
}
.reserve{
  background-color: #d62828;
  color: #fff;
}
</style>

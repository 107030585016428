<template>
    <div class="icon">  <b-icon :icon="currentIcon" />
    </div>
</template>

<script>
export default {
  props: ["shift"],

  computed: {
    currentIcon() {
      if (this.shift === "Dzień") {
        return "sun";
      }
      if (this.shift === "Noc") {
        return "moon";
      }
      return "house-door";
    },
  },
};
</script>
<style scoped>
.icon {
  border: 0;
  font-size: 30px;
}
</style>